import { render, staticRenderFns } from "./BoardsList.vue?vue&type=template&id=653bb86c&scoped=true&"
import script from "./BoardsList.vue?vue&type=script&lang=ts&"
export * from "./BoardsList.vue?vue&type=script&lang=ts&"
import style0 from "./BoardsList.vue?vue&type=style&index=0&id=653bb86c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "653bb86c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VVirtualScroll } from 'vuetify/lib/components/VVirtualScroll';
installComponents(component, {VListItem,VListItemContent,VListItemTitle,VVirtualScroll})
