
















import Vue from "vue";
import { mapGetters } from "vuex";
import { BoardConfig } from "@/options/boardOptions";

export default Vue.extend({
  name: "BoardsList",
  props: {
    selectedId: {
      type: String,
      required: false
    }
  },
  computed: {
    ...mapGetters("boards", ["unencryptedBoards"])
  },
  methods: {
    onClick(board: BoardConfig) {
      this.$emit("onClick", board);
    },
    boardActive(board: BoardConfig) {
      return board.id === this.selectedId;
    }
  }
});
