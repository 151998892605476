


















































import { Layout, Theme } from "@/store/types";
import Vue from "vue";

export default Vue.extend({
  name: "BoardTemplatesList",
  components: {
    BoardTemplatePreviewForm: () =>
      import(
        /* webpackPrefetch: true */ "@/components/Forms/Boards/BoardTemplatePreviewForm.vue"
      )
  },
  props: {
    items: {
      type: Array as () => Layout[] | Theme[],
      required: true
    }
  },
  data: () => ({
    dialog: false,
    preview: null as null | Layout | Theme
  }),
  methods: {
    showPreview(item: Layout | Theme) {
      this.preview = item;
      this.dialog = true;
    }
  }
});
