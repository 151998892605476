



















































































































































































































































































import Vue from "vue";
import BoardsList from "@/components/Boards/BoardsList.vue";
import BoardTemplatesList from "@/components/Boards/BoardTemplatesList.vue";
import { NewPersonalBoardConfig, BoardConfig } from "@/options/boardOptions";
import { notificationStatus } from "@/options/notificationOptions";
import { downloadData, uploadData } from "@/plugins/storage";
import { mapGetters, mapActions } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
import { Board, Layout, Theme } from "@/store/types";
import { mdiPlus, mdiClose } from "@mdi/js";
import { db, storage } from "@/plugins/firebase";
type Modes = "new" | "existing";
const NewPersonalBoard = Object.freeze(NewPersonalBoardConfig);
export default Vue.extend({
  name: "BoardForm",
  components: { BoardsList, BoardTemplatesList },
  data() {
    return {
      name: "",
      layout: null as Layout | null,
      theme: null as Theme | null,
      existingConfig: undefined as undefined | BoardConfig,
      existingBoard: undefined as undefined | Board,
      selectedVisionbookId: "",
      creationMode: "new" as Modes,
      creationModePicked: false,
      creationModes: [
        { label: "Create a new board", value: "new" },
        { label: "Create from an existing board", value: "existing" }
      ],
      newVbCurrentStep: 1,
      existingVbCurrentStep: 1,
      steps: 4,
      stepObjects: [
        { name: "Name", icon: "vb-text small" },
        { name: "Layout", icon: "vb-layout" },
        { name: "Theme", icon: "vb-theme" },
        { name: "Done!", icon: "vb-done" }
      ],
      loading: false,
      btnDisabled: false,
      layouts: [] as Layout[],
      themes: [] as Theme[],
      svgPlus: mdiPlus,
      svgClose: mdiClose
    };
  },
  computed: {
    ...mapGetters("boards", ["getBoardStorageRef", "unencryptedBoardExist"]),
    ...mapGetters("users", ["uid"])
  },
  watch: {
    steps(val) {
      if (this.newVbCurrentStep > val) {
        this.newVbCurrentStep = val;
      }
    }
  },
  async mounted() {
    const layoutsSnapshot = await db
      .collection("layouts")
      .orderBy("order")
      .get();
    this.layouts = layoutsSnapshot.docs.map(
      doc => ({ id: doc.id, ...doc.data() } as Layout)
    );
    const themesSnapshot = await db
      .collection("themes")
      .orderBy("order")
      .get();
    this.themes = themesSnapshot.docs.map(
      doc => ({ id: doc.id, ...doc.data() } as Theme)
    );
    this.layout = this.layouts[0];
    this.theme = this.themes[0];
  },
  methods: {
    ...mapActions("boards", ["switchCreateInProgress"]),
    ...mapActions("notifications", ["notification"]),
    setMode(mode: Modes) {
      this.$emit("pickCreationMode");
      if (mode === "new") {
        this.existingConfig = undefined;
        this.existingBoard = undefined;
      }
      this.creationMode = mode;
      this.creationModePicked = true;
    },
    async pickExistingConfig(board: BoardConfig) {
      this.selectedVisionbookId = board.id;
      const boardStorageRef = this.getBoardStorageRef(board.id);
      const config = await downloadData(boardStorageRef);
      this.existingConfig = config as BoardConfig;
      this.existingBoard = (
        await db
          .collection("boards")
          .doc(this.selectedVisionbookId)
          .get()
      ).data() as Board;
    },
    nextStep(n: number) {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if (n === this.steps) {
        this.newVbCurrentStep = 1;
      } else {
        this.newVbCurrentStep = n + 1;
      }
    },
    prevStep(n: number) {
      if (n === 1) {
        this.newVbCurrentStep = 1;
      } else {
        this.newVbCurrentStep = n - 1;
      }
    },
    setLayout(val: any) {
      this.layout = val;
    },
    setTheme(val: any) {
      this.theme = val;
    },
    async submit() {
      this.btnDisabled = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.newVbCurrentStep = 1;
        return;
      }
      if (this.creationMode === "new") {
        if (window.Cypress) {
          await this._addBoard(this.name, undefined);
        } else {
          if (!this.layout) {
            this.newVbCurrentStep = 2;
            return;
          }
          if (!this.theme) {
            this.newVbCurrentStep = 3;
            return;
          }
          const config = await downloadData(storage.ref(this.theme.path));
          await this._addBoard(this.name, config as BoardConfig);
        }
      } else {
        await this._addBoard(this.name, this.existingConfig);
      }
      this.btnDisabled = false;
    },
    async _addBoard(
      name: string,
      visionBookData: BoardConfig | undefined
    ): Promise<void> {
      if (
        name.length >= 1 &&
        (window.Cypress ||
          (visionBookData &&
            Object.keys(visionBookData).length > 0 &&
            !("encryption" in visionBookData)))
      ) {
        await this.switchCreateInProgress(true);
        this.loading = true;
        const newBoard = await this.$store.dispatch("boards/addBoard", {
          name,
          layoutId:
            this.creationMode === "new"
              ? this.layout?.id || null
              : this.existingBoard?.layoutId || null,
          themeId:
            this.creationMode === "new"
              ? this.theme?.id || null
              : this.existingBoard?.themeId || null
        });
        const finalData = {
          ...NewPersonalBoard,
          ...visionBookData
        };
        await uploadData(
          this.getBoardStorageRef(newBoard.id),
          finalData,
          this.uid
        );
        this.$emit("close");
        await this.switchCreateInProgress(false);
        const nextRoute: { path: string; hash?: string } = {
          path: `/boards/${newBoard.id}`
        };
        if (this.$route.path !== nextRoute.path)
          await this.$router.push(nextRoute);
      } else {
        this.notification({
          ...notificationStatus.ERROR,
          message: this.$t("common.error")
        });
        this.$emit("close");
      }
    }
  },
  validations() {
    return {
      name: { required, minLength: minLength(1) }
    };
  }
});
